@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.dates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0;
}

.dates__item {
  width: 100%;
  font-weight: 600;
  text-align: center;
  color: #00abe9;
  text-decoration-line: underline;
  cursor: pointer;
  font-size: 14px !important;
}

.second-modal {
  justify-content: center !important;
}

.second-modal__accept {
  /* background: #00abe9 !important; */
  background: #0e4ffa !important;
  color: #fff !important;
  width: 166px !important;
  height: 40px !important;
  font-size: 14px !important;
  text-transform: none !important;
}

.second-modal__cancel {
  width: 166px !important;
  height: 40px !important;
  /* border: 1px solid #00abe9 !important; */
  border: 1px solid #0e4ffa !important;
  color: #0e4ffa!important;
  font-size: 14px !important;
  text-transform: none !important;
}

.create-signal__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
/*
.create-signal__wrapper .MuiTypography-root {
  font-weight: 600;
  font-family: "Open Sans" !important;
}
.create-signal__wrapper .MuiInputLabel-formControl {
  z-index: 1;
  letter-spacing: -0.24px;
  padding-left: 16px;
  font-size: 16px;
  top: -4px;
}
.create-signal__wrapper .MuiTextField-root .MuiInputLabel-formControl {
  padding-left: 2px;
  padding-top: 4px;
}

.create-signal__wrapper .MuiInputBase-root {
  background-color: #fcfcfc;
}
.create-signal__wrapper .MuiFormControl-root {
  width: 31% !important;
  margin: 10px 0 10px 20px;
}
.create-signal__wrapper .MuiOutlinedInput-input {
  padding: 14.5px 14px;
}
.create-signal__wrapper .MuiInputLabel-shrink {
  top: -6px !important;
}
.create-signal__wrapper .MuiTextField-root .MuiInputLabel-shrink {
  padding-top: 8px !important;
} */

.create-signal__text {
  padding: 20px 24px 16px;
  margin: 0 !important;
}

.create-modal__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 65px;
  margin-left: 8px;
}

@media (max-width: 900px) {
  .create-modal__buttons {
    flex-flow: column;
    margin: 0;
  }


  #confirmation-dialog-title {
    font-size: 16px;
    padding: 10px 0;
  }
  
  .create-signal__text {
    padding: 0;
    display: block;
    width: 100%;
  }
}

.create-modal__button {
  border: 1px solid #0e4ffa !important;
  box-sizing: border-box;
  border-radius: 10px !important;
  width: 200px;
  height: 40px;
  font-size: 15px;
  font-family: "Roboto" !important;
  line-height: 22px;
  text-transform: initial !important;
  margin-right: 16px !important;
}

@media (max-width: 900px) {
  .create-modal__button {
    margin: 0 0 15px!important;
  }
}

.create-modal__cancel {
  color:#0E4FFA !important;
}
.create-modal__accept {
  color: white !important;
  background-color: #0e4ffa !important;
}
.create-modal__accept_margin {
  margin: 10px 48px !important; 
}

.text-yUu-XgwOPwZDJ_6TmhOLr {
  width: 20% !important;
}
.text-ZZ0-GuSGXuFOhJIMIxes9 {
  width: 20% !important;
}
.select-Cy0FGuQ76TIELCyLW5pMX {
  width: 53% !important;
}


@media (max-width: 900px) {
  .select-Cy0FGuQ76TIELCyLW5pMX,
  .text-ZZ0-GuSGXuFOhJIMIxes9,
  .text-yUu-XgwOPwZDJ_6TmhOLr {
    width: 100%!important
  }
}