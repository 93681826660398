@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.table .table__row {
  @media (max-width: 900px) {
    display: flex;
    flex-flow: column;
  }
}

.table {
  position: relative;
  &__title {
    font-weight: 600;
    font-size: 28px;

    @media (max-width: 900px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  &-footer {
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    .MuiPaginationItem-textPrimary.Mui-selected {
      background-color: #0E4FFA;
      border-radius: 10px;
    }
    .selects {
      display: flex;
      align-items: center;
      .MuiInputBase-root {
        height: 40px;
        margin-left: 8px;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-flow: column;
      align-items: flex-start;
    }
  }

  &__button {
    & a {
      text-decoration: none;
    }
    button {
      font-family: 'Roboto';
      background: #0E4FFA;
      border-radius: 10px;
      border: 0;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 17px;
      cursor: pointer;
      svg {
        margin-right: 5px;
      }
      span{
        font-size: 18px;
      }
    }
  }

  &__content {
    margin-top: 15px;
    background-color: #fff;
    padding: 5px 0 0;
    & * {
      font-family: "Roboto" !important;
    }
    & .MuiTableRow-head * {
      color: #A2A1A6;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 400;
    }  
    & .MuiTableCell-body{
      white-space: nowrap;
      border: none !important;

      @media (max-width: 900px) {
        white-space: unset;
        font-size: 14px;
      }
    }
    & .MuiTableRow-root {
      border-bottom: 1px solid #ECECEC;
    }
    & .child-status {
      border-bottom: none;  
    }
    & .MuiTableContainer-root{
      width: 83% !important;

      @media (max-width: 900px) {
        width: 100% !important;
      }
    }
    & .MuiTableCell-alignRight {
      border-bottom: 1px solid #ECECEC !important;
    }
  }

  &__search {
    background: #F4F4F4;
    border-radius: 10px;
    padding: 13px;
    display: flex;
    align-items: center;
    margin: 0 5px;

    @media (max-width: 900px) {
      flex-flow: column;
      align-items: flex-start;
    }

    input {
      width: 89%;
      height: 40px;
      border: 1px solid #ECECEC;
      box-sizing: border-box;
      border-radius: 10px;
      text-indent: 15px;

      @media (max-width: 900px) {
        margin-bottom: 10px;
        width: 100%;
      }
    }

    button {
      font-family: 'Roboto';
      background: #292931;
      border-radius: 10px;
      color: #ffffff;
      border: 0;
      width: 10%;
      min-width: 120px;
      height: 40px;
      margin-left: 1%;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 900px) {
        margin-right: 0;
      }

      cursor: pointer;
      svg {
        margin-left: 15px;
        width: 18px;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }

  .statuses {

    border-radius: 8px;
    text-align: center;

    span {
      color: #ffffff;
      font-weight: 500;
      text-align: center;
    }
  }
}



.addRecord {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.anketa-statuses {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  .anketa-item {
  }
}

.identifying-status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  .identifying-status__item {
    display: flex;
    align-items: center;
    // margin: 12px 0;
    margin: 5px 0;
    margin-right: 1%;
    background-color: #fff;
    padding: 5px 10px 5px 15px;
    // width: 19%;
    border-radius: 10px;
    min-width: 220px;


    div {
      padding: 1px 28px;
      margin-left: 10px;
      border-radius: 90px;
    }
    span {
      white-space: nowrap;
    }

    @media (max-width: 900px) {
      flex-flow: column;
      align-items: flex-start;
      border-bottom: 1px solid #cecece;
      border-radius: 0;

      div {
        margin: 15px 0 0;
      }
    }

  }
}
.child-status {
  // background-color: rgba(14, 79, 250, 0.05);
  padding-top: 10px;
}
// .collapsible-table {
  .anketa-button {
    font-weight: 500;
    border: 2px solid #0E4FFA;
    box-sizing: border-box;
    border-radius: 10px;
    color: #0E4FFA;
    padding: 8px 25px;
    cursor: pointer;
    outline: none;
    background-color: white;
    min-width: 162px;
  }
// }
.table__absolute-buttons{
  position: absolute;
  right: 32px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid #ececec;
  padding: 15px 16px 14px 16px !important;
  &_padding_global {
    padding: 10.5px !important;
  }

  @media (max-width: 900px) {
    position: static;
  }
}

.table__absolute-buttons.MuiTableCell-alignRight {
  @media (max-width: 900px) {
    text-align: left;
  }
}

.table__absolute-header-cell{
  height: 60px;
  width: 194px;
}
.create-anketa-button__wrapper{
  width: 396px;
  margin-right: 8px;
  display: flex;
  width: 100%;
}
.create-anketa-button {
  padding: 8px 40px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 90px;
  margin: 10px 0 0 300px;
  background: rgba(58, 192, 159, 0.2);
  color: #3AC09F;
  border:none;
  outline: none;
}

@media (max-width: 900px) {
  .create-anketa-button {
    margin: 10px 0;
  }
}

.anketa-history{
  width: 100%;
  padding: 10px 0 10px 10px;
  font-size: 16px;
  text-transform: uppercase;
  color: #0E4FFA;
}