$accent: #0E4FFA;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background: #F0F4FD;
}

@media (min-width: 500px) {
  #inn-input-helper-text {
    width: 370px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.MuiDialogContent-root.MuiDialogContent-dividers legend.MuiFormLabel-root,
.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: #000;
}

.MuiDialogContent-root.MuiDialogContent-dividers input {
  font-size: 16px !important;
  // font-weight: 600 !important;
}

.MuiDialogContent-root.MuiDialogContent-dividers .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-filled,
.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-size: 16px !important;
}


.App-wrap {
  display: flex;
}

.menuDrawer {
  .MuiDrawer-paperAnchorDockedLeft {
    width: 253px !important;
    background-color: $accent;
    position: fixed!important;
  }

  .MuiDrawer-paper {
    position: static;
    // min-height: 100vh;
  }

  ul {
    list-style: none;
    color: #ffffff;
    margin: 20px 0;
    padding-left: 20px;

    li {
      font-weight: 600;
      cursor: pointer;
      font-size: 20px;
      margin: 0;
      margin-bottom: 22px;
    }

    .active {
      div {
        color: $accent;
        background: #FCFCFC;
        border-radius: 90px 0px 0px 90px;
        svg {
          path {
            fill:  $accent
          }
        }
      }
    }

    a {
      text-decoration: none;
      padding: 16px 0;
      width: 100%;
      color: #fff;

      div {
        color: #ffffff;
        display: flex;
        align-items: center;
        padding: 15px 20px;

        svg {
          margin-right: 20px;
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  .logo {
    color: #ffffff;
    text-align: center;
    padding-top: 30px;
    font-size: 20px;
  }

  .sidebar__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    & .user {
      display: flex;
      align-items: center;
      margin: 0 0 30px 30px;
      cursor: pointer;
      position: relative;
      padding-bottom: 30px;
      &__interface {
        position: absolute;
        background-color: #fff;
        padding: 10px 20px;
        top: -50px;
        transform: translateX(-100%);
        right: 0;
        border-radius: 10px;
      }
      &__avatar{
        background-color: white;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 13px;
      }
      &__info{
        color: white;
        font-weight: bold;
        &-name{
          // font-family: "Gotham";
          font-size: 14px;
        }
        &-work{
          margin-top: 4px;
          font-size: 12px;
        }
      }
    }
  }
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.content {
  padding: 30px;
}

.mainTitle {
  font-weight: 600 !important;
  font-size: 28px !important;
  width: 50%;

  @media (max-width: 500px) {
    font-size: 18px!important;
  }
}


.MainPage {
  &__cards {
    justify-content: space-around;
  }

  &__cards-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-right: 25px;
    width: 213px;
    margin-bottom: 24px;
    
    @media (max-width: 560px) {
      width: 100%;
      margin-right: 0;
    }

    p {
      font-weight: 600;
      font-size: 15px;
      display: block;
      position: relative;
      padding: 10px 20px;
    }

    h3 {
      color: #c4c4c4;
    }

    .cards__icon {
      background: #F06B56;
      border-radius: 10.872px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      &_purple {
        background-color: #6462CA;
      }
      &_green {
        background-color: #3AC09F;
      }
      &_blue {
        background-color: #6298CA;
      }
    }

    .cards__content {
      padding: 15px 18px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1366px) {
        padding: 19px 18px;
      }
    }

    .cards__number {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
    }

    .cards__badge {
      padding: 3px 15px;
      border-radius: 90px;

      &.pink {
        color: #F06B56;
        background: rgba(240, 107, 86, 0.3);
      }
      &.purple {
        color: #6462CA;
        background-color: rgba(100, 98, 202, 0.3);
      }
      &.green {
        color: #3AC09F;
        background-color: rgba(58,192,159, 0.3);
      }
      &.blue {
        color: #6298CA;
        background-color: rgba(98,152,202, 0.3);
      }
    }
  }

  .MainPage__cards {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-basis: 40%;
    @media screen and (max-width:  1366px){
      flex-basis: 52%;
    }
    @media screen and (min-width:  1367px){
      flex-basis: 44%;
    }
  }
}
.main__date-pick{
  display: flex;
  align-items: center;
  width: 152px;
  & svg{
    margin: 0 5px 2px 0;
  }
  &.MuiFormControl-root {
    width: 125px !important;
  }
  input {
    color: rgba(117,117,117,1);
  }
}

.content {
  width: 100%;
  margin-left: 254px;
  max-width: 1280px;
  @media screen and  (max-width: 1366px) {
    width: 81%;
    padding: 10px;
  }

  @media (max-width: 900px) {
    margin-left: 0;
    width: 100%;
  }
}

.MainPage-top__wrap {
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-flow: column;
    justify-content: center;
  }
}

.MainPage__map {
  flex-basis: 60%;
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding-bottom: 10px;
  .realtime {
    text-align: right;
    margin-right: 15px;
  }
  svg {
    cursor: pointer;
    
    @media (max-width: 900px) {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    margin-bottom: 20px;
  }
}

.charts__Wrap {
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-flow: column;
    align-items: center;
  }

  .bottom-charts {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    width: 359px;
    height: 452px;


    .bottom-charts {
      flex-basis: 40%;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
    &__title{
      padding: 10px 20px;
      border-bottom: 1px solid #C4C4C4;
      font-weight: 600;

      @media (max-width: 900px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 1366px){
      height: auto;
    }
    @media screen and (min-width: 1367px){
      margin-right: 20px;
    }

    @media (max-width: 900px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}
.charts__line {
  width: 68%;
  max-height: 500px;
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 12px;


  @media screen and (max-width: 1366px){
    width: 63%;
  }

  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
.mapBlock {
  position: absolute;
  background: #4a4a4a;
  border-radius: 4px;
  padding: 5px 15px;
  color: #ffffff;
  transition: 0.25s ease;
  div {
    font-size: 14px;
  }
  &__info {
    div {
      display: flex;
      align-items: center;
    }
    .badge {
      width: 12px;
      height: 12px;
      background-color: #6462CA;
      margin-right: 8px;
      &_red{
        background-color: #F06B56;
      }
      &_green{
        background-color: #3AC09F;
      }
      &_blue{
        background-color: #6298CA;
      }
    }
  }
}
.map__holder__wrapper {
  flex-direction: column;
  align-items: baseline !important;
}

.map__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #C4C4C4;
  .map__name {
    font-size: 15px;
    color: #000000;
    font-weight: 600;
  }
  .map__date {
    color: #757575;
    font-size: 15px;
    font-weight: 600;
  }
}
.mapKyrgyzstan {
  text-align: center;
  margin-top: 17px;
  .realtime {
    position: absolute;
    right: 15px;
    bottom: 0;
    font-size: 13px;
  }
}


.MuiContainer-maxWidthLg {
  max-width: 1136px!important;
}

.d-flex{
  display: flex;
}

.fullscreen__dialog {
  .MuiDialog-paper {
    max-width: 100%!important;
  }
  & .MuiDialog-paperFullScreen{
    background-color: #e0e0e0;
  }
  & .MuiContainer-root{
    background-color: white;
    margin: auto;
    border-radius: 10px;
  }
  & .MuiFormControl-fullWidth{
    width: 31%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }
  & .MuiFormControl-root{
    margin:  10px !important;

    @media (max-width: 900px) {
      margin: 10px 0!important;
    }
  }
}

.MuiOutlinedInput-root{
  border-radius: 10px !important;
  border: 1px solid #ECECEC;
  // background: #FCFCFC;
}

.add-signal__container {
  width: 1042px !important;

  @media (max-width: 900px) {
    width: 100%!important;
  }
}

.paginations {
  padding: 20px;
}

@media (max-width: 900px) {
  .MuiDialogContent-root {
    padding: 0!important;
  }
}






.TasksPage__heading {
  display: flex;
  align-items: center;
  margin: 0 -10px;

  @media (max-width: 950px) {
    flex-flow: column;
    padding: 10px;
  }
}

.TasksPage__dialog.MuiDialogContent-root {
  padding: 10px!important;
}

.Button.MuiButton-root {
  font-family: 'Roboto';
  background: #0E4FFA;
  border-radius: 10px;
  border: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 17px;
  cursor: pointer;
  text-transform: initial;
}

.Button--text.MuiButton-root {
  background: transparent;
  color: #0E4FFA; 
}

.TasksPage__dialog-buttons {
  display: flex;
  justify-content: center;

  > button:first-child {
    margin-right: 10px;
  }
}





.ReportPage {
  &__heading {
    
  }

  .ReportTable td {
    padding: 5px;
  }

  .ReportTable__num,
  .ReportTable__index {
    text-align: center;
    padding: 0;
  }

  .ReportTable__total-cell-index,
  .ReportTable__index {
    border-right: 1px solid #cfcfcf;
  }

  .ReportTable__title {
    margin-bottom: 20px;
    font-weight: 600;
  }

  .ReportTable__total-cell-index,
  .ReportTable__total-cell-title,
  .ReportTable__total-cell-count {
    font-weight: 600;
  }

  .ReportTable thead * {
    font-weight: 600;
  }

  .ReportTable tbody td:not(.ReportTable__index) {
    padding-left: 16px;
    padding-right: 16px;
  }


  .ReportCard {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }

  .ReportCard__title {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ReportCard__value {
    margin-bottom: 10px;
  }

  .ReportCard__subtitle {
    margin-bottom: 5px;
    font-size: 14px;
    color:rgba(43, 53, 63, 0.9);
  }
}