// .text-nKG3pt0NbAMH6Z6LFs8g7 {
//     width: 47% !important;
// }

// ! unit 1

.select-aeozhPQSFy4YYLNlXUbJr {
    width: 64% !important;
}
.select-URRVEdr_Hjzbdu1O_DtS9 {
    width: 47% !important;
}
.select-kylqALnvX-F-2ZYXe_-NZ {
    width: 48% !important;
}
.select-Yagc_6g1qrQxjftjz9X3l {
    width: 97% !important;
}
.text-COSvfkJynsUx01b-pcDYS {
    width: 47% !important;
}
.text-ZIDQPZG7EkBa1ftBj34Ts {
    width: 48% !important;
}
.text-DWfptvzuMTg8CtRbtAYfZ {
    width: 47% !important;
}
.text-x9b75KtvTmi2ktsBvZMzQ {
    width: 48% !important;
}
.select-jgNoONbuzGOO47rmIUcsz {
    width: 97% !important;
}
.radio-bbktrc1kpl1EqkfZPuVXC {
    order: 1;
}
.radio-w9ZiWgLbqb6Vgz9LBaAbT {
    order: 1;
}


// ! unit 3
// .select-jD-VkKwjK5daYBUWRPfR8 {
    // width: 47% !important;
// }
.select-d64866lZk201uKPBu1DTa {
    width: 64% !important;
}
.textArea-WgLLkjdpVB8qS1vK-6eSU {
    width: 97% !important;
}
.text-gOV7z0cNwEJs0f_zH4Oj9 {
    width: 97% !important;
    order: -1
}

// ! unit 4
.date-q-5kmU-oufXycQ2o3lD4T  {
    width: 47% !important;
    order: -4
}
.textArea-U1IquLgyPpTI2mezdJUHZ {
    width: 47% !important;
    order: -3
}
.date-7yn4xGsHjSfln3CLyn0Rw {
    width: 47% !important;
    order: -2
}
.date-w2Dwrm4RkP7J4AFCl5jKs {
    width: 47% !important;
    order: -1
}

// ! unit 5
.select-dffDTf-z0UWf1PYxqQ7ER {
    width: 47% !important;
    order: 1
}

// ! unit 6
.textArea-36RnXF3i6lH_SGfPMlsg9 {
    width: 47% !important;
    order: -2
}
.textArea-_sVYVYmFSs0Q2ZMCoSb9H {
    width: 47% !important;
    order: -1
}

// ! unit 10
.select-I9UBBKD-jyJnxpF3PHEhm {
    width: 47% !important;
    order: -2
}
.select-m3vXFSlqypjs6f-MTMOk5 {
    width: 47% !important;
    order: -1
}

// ! create-signal




@media (max-width: 900px) {
    .select-aeozhPQSFy4YYLNlXUbJr,
    .select-URRVEdr_Hjzbdu1O_DtS9,
    .select-kylqALnvX-F-2ZYXe_-NZ,
    .select-Yagc_6g1qrQxjftjz9X3l,
    .text-COSvfkJynsUx01b-pcDYS,
    .text-ZIDQPZG7EkBa1ftBj34Ts,
    .text-DWfptvzuMTg8CtRbtAYfZ,
    .text-x9b75KtvTmi2ktsBvZMzQ,
    .select-jgNoONbuzGOO47rmIUcsz,
    .radio-bbktrc1kpl1EqkfZPuVXC,
    .radio-w9ZiWgLbqb6Vgz9LBaAbT,
    .select-d64866lZk201uKPBu1DTa,
    .textArea-WgLLkjdpVB8qS1vK-6eSU,
    .text-gOV7z0cNwEJs0f_zH4Oj9,
    .date-q-5kmU-oufXycQ2o3lD4T ,
    .textArea-U1IquLgyPpTI2mezdJUHZ,
    .date-7yn4xGsHjSfln3CLyn0Rw,
    .date-w2Dwrm4RkP7J4AFCl5jKs,
    .select-dffDTf-z0UWf1PYxqQ7ER,
    .textArea-36RnXF3i6lH_SGfPMlsg9,
    .textArea-_sVYVYmFSs0Q2ZMCoSb9H,
    .select-I9UBBKD-jyJnxpF3PHEhm,
    .select-m3vXFSlqypjs6f-MTMOk5 {
        width: 100% !important;
    }
}